﻿//JS Dependencies
import 'bootstrap';

//Pull to Refresh JS Setup
import PullToRefresh from 'pulltorefreshjs';
const ptr = PullToRefresh.init({
    mainElement: 'body',
    onRefresh() {
        window.location.reload();
    }
});

//CSS Dependencies
//import 'bootstrap/dist/css/bootstrap.css'; //Replaced with bootswatch theme 
import 'bootswatch/dist/superhero/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

//Custom CSS
import '../css/site.css';

